import { initializeApp } from 'firebase/app'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'
const app = initializeApp({

    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ,
  
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ,
  
    databaseURL: process.env.REACT_APP_DATABASE_URL,
  
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  
    messagingSenderId:  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ,
  
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  
  }  )

export const auth = getAuth(app);
export const methods = {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut
}
export default app