import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faHome,
  faEnvelope,
  faPhone,
  faAsterisk,
  faSuitcase,
  faCertificate,
  faNetworkWired,
  faGlobe
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faGithub,
  faHackerrank,
} from "@fortawesome/free-brands-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import "./App.css";

// Import the functions you need from the SDKs you need

import { getDatabase, ref, child, get } from "firebase/database";

import { getStorage, getDownloadURL,ref as refImg } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import app from "./firebase"

const database = getDatabase(app);
const storage =getStorage(app)

function App() {
  const [data, setData] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [language, setLanguage] = useState(true); 

  useEffect(() => {
    const dbRef = ref(database);
    get(child(dbRef, "/" + (language ? "An" : "Fr") + "/"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setData(snapshot.val());
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    const getImageUrl = async () => {
      try {
        const storageRef = refImg(storage, 'Photo.png');
        const downloadUrl = await getDownloadURL(storageRef);
        setImageUrl(downloadUrl);
      } catch (error) {
        console.log('Error getting image from Firebase Storage:', error);
      }
    };

    getImageUrl();
  
  }, [language]);
  if (!data) {
    return <p>Loading data...</p>;
  }
  return (
    <>
    <center>
      <div className="switch">
          <input id="language-toggle" className="check-toggle check-toggle-round-flat" type="checkbox" onChange={()=>{setLanguage(!language)}}/>
          <label htmlFor="language-toggle"></label>
          <span className="on">EN</span>
          <span className="off">FR</span>
        </div>
    </center>
    <div className="w3-content w3-margin-top" style={{ maxWidth: "1400px" }}>
      <div className="w3-row-padding">
        <div className="w3-third">
          <div className="w3-white w3-text-grey w3-card-4">
            <div className="w3-display-container">
              <img
                src={imageUrl}
                style={{ width: "100%" }}
                alt="Avatar"
              />
            </div>
            <div className="w3-container">
              <p>
                <h4>{data.name}</h4>
              </p>
              <h2>
                <FontAwesomeIcon
                  icon={faBriefcase}
                  className="fa-fw fa-5x w3-margin-right w3-large w3-text-teal"
                />
                {data.Job}
              </h2>
              <p>
                <FontAwesomeIcon
                  icon={faGlobe}
                  className="fa-fw w3-margin-right w3-large w3-text-teal"
                />
                {data.Site}
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faHome}
                  className="fa-fw w3-margin-right w3-large w3-text-teal"
                />
                {data.Address}
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="fa-fw w3-margin-right w3-large w3-text-teal"
                />
                {data.Email}
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faPhone}
                  className="fa-fw w3-margin-right w3-large w3-text-teal"
                />
                {data.Phone}
              </p>
              <hr />
              <p className="w3-large">
                <b>
                  <FontAwesomeIcon
                    icon={faAsterisk}
                    className="fa-fw w3-margin-right w3-text-teal"
                  />
                  {(language ? "Skills" : "Compétences")}
                  
                </b>
              </p>
              {data.Skills.map((skill) => {
                return (
                  <div key={skill.index}>
                    <p>{skill.Name}</p>
                    <div className="w3-light-grey w3-round-xlarge w3-small">
                      <div
                        className="w3-container w3-center w3-round-xlarge w3-teal"
                        style={{ width: skill.Percentage + "%" }}
                      >
                        {skill.Percentage}%
                      </div>
                    </div>
                  </div>
                );
              })}
              <br />

              <p className="w3-large w3-text-theme">
                <b>
                  <i className="fa fa-globe fa-fw w3-margin-right w3-text-teal"></i>
                  {(language ? "Languages" : "langues")}
                </b>
              </p>
              {data.Languages.map((Language) => {
                return (
                  <div key={Language.index}>
                    <p>{Language.Name}</p>
                    <div className="w3-light-grey w3-round-xlarge">
                      <div
                        className="w3-round-xlarge w3-teal"
                        style={{
                          height: "24px",
                          width: Language.Percentage + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
              <br />
            </div>
          </div>
          <br />

          {/*<!-- End Left Column -->*/}
        </div>
        {/*<!-- Right Column -->*/}
        <div className="w3-twothird">
          <div className="w3-container w3-card w3-white w3-margin-bottom">
            <h2 className="w3-text-grey w3-padding-16">
              <FontAwesomeIcon
                icon={faSuitcase}
                className="w3-margin-right w3-xxlarge w3-text-teal"
              />
              {(language ? "Work Experience" : "L'expérience professionnelle")}
              
              
            </h2>


            {data.Experience.map((item) => {
                return (
                 
                    <div className="w3-container" key={item.index}>
                    <h5 className="w3-opacity">
                      <b>{item.Title}</b>
                    </h5>
                    <h6 className="w3-text-teal">
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className="fa-fw w3-margin-right"
                      />
                      {item.Begin} - {item.End=="Current"||item.End=="Actuel"?(

                      <span className="w3-tag w3-teal w3-round">{item.End}</span>):item.End}
                    </h6>
                    <p>
                      <ul>
                      {item.Content.map((item) => {return (<li>{item}</li>)})}
                      </ul>
                     
                    </p>
                    <hr />
                  </div>
                );
              })}
          </div>
          <div className="w3-container w3-card w3-white">
            <h2 className="w3-text-grey w3-padding-16">
              <FontAwesomeIcon
                icon={faCertificate}
                className="w3-margin-right w3-xxlarge w3-text-teal"
              />
              {(language ? "Education":"Éducation" )}
            </h2>

            {data.Education.map((item) => {
                return (
                 
                    <div className="w3-container" key={item.index}>
                    <h5 className="w3-opacity">
                      <b>{item.Title}</b>
                    </h5>
                    <h6 className="w3-text-teal">
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className="fa-fw w3-margin-right"
                      />
                      {item.Begin} - {item.End=="Current"||item.End=="Actuel"?(

                      <span className="w3-tag w3-teal w3-round">{item.End}</span>):item.End}
                    </h6>
                    <p>
                     {item.Content}
                    </p>
                    <hr />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <footer className="w3-container w3-teal w3-center w3-margin-top">
        <p>Find me.</p>
        <div className="icon-container">
          <a href="https://www.hackerrank.com/bzquatre" target="_blank"><FontAwesomeIcon icon={faHackerrank}  className="w3-hover-opacity" /></a>
          <a href="https://www.linkedin.com/in/bouzegzegzakaria" target="_blank"><FontAwesomeIcon icon={faLinkedin} className="w3-hover-opacity" /></a>
          <a href="https://www.github.com/bzquatre" target="_blank"><FontAwesomeIcon icon={faGithub} className="w3-hover-opacity" /></a>
        </div>
        <p>
        You will find in {" "}
          <a href="https://cv.bzquatre.com" target="_blank">
            cv.bzquatre.com
          </a>
        </p>
      </footer>
    </div>
    </>
  );
}

export default App;